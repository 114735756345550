import {createRouter, createWebHashHistory} from 'vue-router'
import RouteNames from "@/constants/route-names";
import {useAuthenticationStore} from "@/stores/authentication-store";

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: RouteNames.ROUTE_LINK_VIEW,
        component: () => import('@/views/link/LinkView'),
    },
    {
        path: '/link/:linkId',
        name: RouteNames.ROUTE_LOAD_LINK,
        component: () => import('@/views/link/LoadLink'),
    },
    {
        path: '/social-post/user',
        name: RouteNames.ROUTE_SOCIAL_POST_USER,
        component: () => import('@/views/social-post/SocialPostUser'),
    },
    {
        path: '/social-post/user/:socialPostUser/:socialPostUserId/list',
        name: RouteNames.ROUTE_SOCIAL_POST_LIST,
        component: () => import('@/views/social-post/SocialPostList'),
    },
    {
        path: '/login',
        name: RouteNames.ROUTE_LOGIN,
        component: () => import('@/views/Login'),
    },
    {
        path: '/',
        name: RouteNames.ROUTE_HOME,
        component: () => import('@/views/Home'),
        meta: {
            requiresAuthentication: true
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuthentication) {
        if ((useAuthenticationStore()).isLoggedIn) {
            next()
        } else if (from.path === '/') {
            next({name: RouteNames.ROUTE_LINK_VIEW})
        } else {
            next({name: RouteNames.ROUTE_LOGIN})
        }
    } else {
        next()
    }
})

export default router