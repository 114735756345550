export default class ResultCodes {
    static EXCEPTION_OCCURRED = 10000;
    static INVALID_PARAMS = 10001;
    static JWT_EXPIRED = 10002;
    static JWT_VALID = 10003;
    static JWT_NOT_FOUND = 10004;
    static JWT_EXCEPTION = 10005;
    static LOG_INSERTED = 10006;
    static DELETED_USER = 10007;
    static USER_AUTHENTICATED = 10008;
    static ENV_VARIABLE_NOT_FOUND = 10009;
    static INVALID_CONTROLLER = 10010;
    static INVALID_CREDENTIALS = 10011;
    static INVALID_DAL = 10012;
    static INVALID_NAMESPACE = 10013;
    static REQUEST_VARIABLE_NOT_FOUND = 10014;
    static ROUTE_NOT_FOUND = 10015;
    static INVALID_MODEL = 10016;
    static INVALID_TYPE = 10017;
    static INVALID_SLACK_CONFIGURATION = 10018;
    static SLACK_MESSAGE_ERROR = 10019;
    static SLACK_MESSAGE_SENT = 10020;
    static ROW_INSERTED = 10021;
    static INVALID_RECAPTCHA_RESPONSE = 10022;
    static S3_UPLOAD_EXCEPTION = 10023;
    static S3_FILE_UPLOADED = 10024;
    static MISCONFIGURED_S3_UPLOADER = 10025;
    static WORKER_JOB_ADDED = 10026;
    static INVALID_WORKER_QUEUE_JOB_ID = 10027;
    static WORKER_JOB_COMPLETED = 10028;
    static WORKER_JOB_UPDATED = 10029;
    static SCHEDULED_DISPATCHER_TOGGLED = 10030;
    static INVALID_INIT_FOR_ENVIRONMENT = 10031;

    static LINK_SHORT_CODE_NOT_FOUND = 100;
    static LINK_NOT_FOUND = 101;
    static SOCIAL_POST_USER_EXISTS = 102;
}
