import { defineStore } from 'pinia'
import axios from "axios";
import SnackbarConstants from "@/constants/snackbar-constants";
import {useSnackbarStore} from "@/stores/snackbar-store";
import RouteNames from "@/constants/route-names";
import Constants from "@/constants/constants";
import ResultCodes from "@/constants/result-codes";
export const useAuthenticationStore = defineStore('authentication-store', {
    state: () => {
        return {
            token: null
        }
    },
    actions: {
        setToken(token) {
            this.token = token
            document.cookie = `link-shortener-jwt=${token}`;
        },
        setUser(user) {
            this.user = user
        },
        async login(username, password) {
            const formData = new FormData()
            formData.append('username', username)
            formData.append('password', password)

            await axios.post('/authentication/login', formData)
                .then(response => {
                    if (response.data.result_code === ResultCodes.USER_AUTHENTICATED) {
                        this.setToken(response.data.jwt)
                        this.setUser(response.data.username);
                        this.router.push({
                            name: RouteNames.ROUTE_HOME
                        });
                        (useSnackbarStore()).displaySnackbar(Constants.LOGGED_IN_MESSAGE, SnackbarConstants.SNACKBAR_SUCCESS)
                    } else {
                        (useSnackbarStore()).displaySnackbar(Constants.UNEXPECTED_ERROR_MESSAGE, SnackbarConstants.SNACKBAR_ERROR)
                    }
                })
                .catch(error => {
                    if (error.response.data.result_code === ResultCodes.INVALID_CREDENTIALS) {
                        (useSnackbarStore()).displaySnackbar(Constants.INVALID_USERNAME_OR_PASSWORD, SnackbarConstants.SNACKBAR_ERROR)
                    } else {
                        (useSnackbarStore()).displaySnackbar(Constants.UNEXPECTED_ERROR_MESSAGE, SnackbarConstants.SNACKBAR_ERROR)
                    }
                })
        },
        logout() {
            this.setToken(null)
            this.setUser(null)
            this.router.push({
                name: RouteNames.ROUTE_LOGIN
            });
            (useSnackbarStore()).displaySnackbar(Constants.LOGGED_OUT_MESSAGE, SnackbarConstants.SNACKBAR_SUCCESS)
        }
    },
    getters: {
        isLoggedIn() {
            return this.token !== null
        },
        getToken() {
            return this.token
        }
    },
    persist: {
        storage: sessionStorage
    }
})