<template>
  <v-navigation-drawer v-model="showDrawer" :temporary="true">
    <v-list>
      <v-list-item
        v-for="item in NAVBAR_ITEMS"
        :key="item.name"
        :title="item.name"
        :to="{name: item.route}"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapWritableState} from "pinia"
import NavbarConstants from "@/constants/navbar-constants"
import {useHeaderStore} from "@/stores/header-store"

export default {
    name: "NavigationDrawer",
    data() {
        return {
            headerStore: useHeaderStore(),
            NAVBAR_ITEMS: NavbarConstants.NAVBAR_ITEMS
        }
    },
    computed: {
        ...mapWritableState(useHeaderStore, ['showDrawer'])
    }
}
</script>

<style scoped>

</style>