import axios from 'axios'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import {createApp, markRaw} from 'vue'
import App from '@/App'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import {version} from '../package.json'
import {useAnalyticStore} from "@/stores/analytic-store";
import {useAuthenticationStore} from "@/stores/authentication-store";

import "@/styles/global.css"
loadFonts()

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
pinia.use(({ store }) => { store.router = markRaw(router) });
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(vuetify)
app.config.globalProperties.app_version = version
app.mount('#app')

axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}api`

const analyticStore = useAnalyticStore()
analyticStore.setUuid()
const authenticationStore = useAuthenticationStore();
axios.interceptors.request.use((config) => {
    const token = authenticationStore.getToken
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    config.params = {...config.params, uuid: analyticStore.getUuid}
    return config
})

analyticStore.addPageAccess()