<template>
  <v-app-bar
    prominent
    color="primary"
    density="comfortable"
  >
    <v-app-bar-nav-icon v-if="isLoggedIn" border rounded="0" @click="toggleDrawer"></v-app-bar-nav-icon>
    <v-app-bar-title style="height: 40px" @click="incrementShowVersionCount"></v-app-bar-title>
    <v-spacer></v-spacer>
    <div v-if="showVersionCount > 10" class="mr-3">
      Version {{ app_version }}
    </div>
    <v-btn :icon="currentThemeIcon" @click="toggleTheme"></v-btn>
    <template v-if="isLoggedIn" #append>
      <v-btn icon="mdi-exit-to-app" @click="logout"></v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import {mapActions, mapState} from "pinia"
import {useHeaderStore} from "@/stores/header-store"
import {useAuthenticationStore} from "@/stores/authentication-store";
import {useTheme} from "vuetify";

export default {
    // eslint-disable-next-line
    name: "Header",
    data() {
        return {
            headerStore: useHeaderStore(),
            theme: useTheme()
        }
    },
    computed: {
        ...mapState(useAuthenticationStore, ['isLoggedIn']),
        ...mapState(useHeaderStore, ['showVersionCount']),
        currentTheme() {
            return this.theme.global.name
        },
        currentThemeIcon() {
            return this.currentTheme === 'dark' ? 'mdi-moon-waning-crescent' : 'mdi-weather-sunny'
        }
    },
    methods: {
        ...mapActions(useAuthenticationStore, {logout: 'logout'}),
        toggleDrawer() {
            this.headerStore.toggleDrawer()
        },
        incrementShowVersionCount() {
            this.headerStore.incrementShowVersionCount()
        },
        toggleTheme() {
            this.theme.global.name = this.currentTheme === 'dark' ? 'light' : 'dark'
            localStorage.setItem('theme-override', this.currentTheme)
        },
    },
}
</script>