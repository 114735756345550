import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
export const useAnalyticStore = defineStore('analytic-store', {
    state: () => {
        return {
            uuid: null
        }
    },
    actions: {
        setUuid() {
            if (this.uuid === null) {
                this.uuid = uuidv4()
            }
        },
        addPageAccess() {
            if (process.env.NODE_ENV !== 'development') {
                axios.get("/link-access").then(() => {})
            }
        }
    },
    getters: {
        getUuid() {
            return this.uuid
        }
    },
    persist: {
        storage: localStorage
    },
})