// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const dark = {
    dark: true,
    colors: {
        background: '#242c2c',
        primary: '#849494',
        secondary: '#e0dad5',
        surface: '#849494',

        error: '#ff2e55',
    },
}
const light = {
    dark: false,
    colors: {
        background: '#e0dad5',
        primary: '#4d535a',
        secondary: '#242c2c',
        surface: '#4d535a',
        error: '#B00020',
    },
}

let loadedTheme = 'light'
const themeOverride = localStorage.getItem('theme-override');
if (themeOverride) {
    loadedTheme = themeOverride
} else {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        loadedTheme = 'dark'
    }
}

export default createVuetify({
    components: {},
    theme: {
        defaultTheme: loadedTheme,
        themes: {
            dark,
            light
        }
    }
})
