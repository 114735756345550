import RouteNames from "@/constants/route-names";

export default class NavbarConstants {

    static NAVBAR_ITEMS = [
        {
            name: 'Home',
            route: RouteNames.ROUTE_HOME,
        },
        {
            name: 'Links',
            route: RouteNames.ROUTE_LINK_VIEW,
        },
        {
            name: 'Social Posts',
            route: RouteNames.ROUTE_SOCIAL_POST_USER,
        },
    ]
}