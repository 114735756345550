import {defineStore} from 'pinia'

export const useSnackbarStore = defineStore('snackbar-store', {
    state: () => {
        return {
            showSnackbar: false,
            message: '',
            color: ''
        }
    },
    actions: {
        toggleDrawer() {
            this.showDrawer = !this.showDrawer
        },
        displaySnackbar(message, color) {
            this.message = message
            this.color = color
            this.showSnackbar = true
        }
    },
})