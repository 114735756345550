import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('header-store', {
    state: () => {
        return {
            showDrawer: false,
            showVersionCount: 0,
        }
    },
    actions: {
        toggleDrawer() {
            this.showDrawer = !this.showDrawer
        },
        incrementShowVersionCount() {
            this.showVersionCount++
        },
    },
})