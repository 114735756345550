<template>
  <v-layout>
    <Snackbar></Snackbar>
    <Header v-if="$route.name !== RouteNames.ROUTE_LOAD_LINK"></Header>
    <NavigationDrawer v-if="isLoggedIn"></NavigationDrawer>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>

import Header from "@/components/Header";
import NavigationDrawer from "@/components/NavigationDrawer";
import RouteNames from "@/constants/route-names";
import Snackbar from "@/components/Snackbar.vue";
import {mapState} from "pinia";
import {useAuthenticationStore} from "@/stores/authentication-store";

export default {
    name: 'App',
    // eslint-disable-next-line
    components: {Snackbar, NavigationDrawer, Header},
    data() {
        return {
            RouteNames:RouteNames
        }
    },
    computed: {
        ...mapState(useAuthenticationStore, ['isLoggedIn'])
    },
    beforeMount() {
        if (process.env.NODE_ENV === 'production') {
            window.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
        }
    }
}
</script>

<style>
</style>
