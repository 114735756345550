<template>
  <v-snackbar v-model="showSnackbar" transition="true" timeout="2000" location="top" :color="color">
    <div class="text-center">
      {{ message }}
    </div>
  </v-snackbar>
</template>

<script>

import {mapState, mapWritableState} from 'pinia'
import {useSnackbarStore} from '@/stores/snackbar-store'

export default {
    // eslint-disable-next-line
    name: 'Snackbar',
    data: () => ({
        snackbarStore: useSnackbarStore
    }),
    computed: {
        ...mapWritableState(useSnackbarStore, ['showSnackbar']),
        ...mapState(useSnackbarStore, ['color']),
        ...mapState(useSnackbarStore, ['message']),
    },
}
</script>
